<script>

/// svg
  import SvgIcon from '@/components/SvgIcon';
/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  VUE CODE
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/
export default {
    /*
  /  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
  /
  /  components
  /
  /  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
  */
  components : {
    SvgIcon,
  }
}
</script>
<template>
<section>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1><svg-icon icon="compu-triste" :hasFill="true"></svg-icon> Lo sentimos la página que solicitaste no existe o no pudo ser encontrada. </h1>
                <div class="separator mt-4 pt-4"></div>
            </div>
        </div>
    </div>
</section>
    
</template>